import { ActionContext } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { Role } from '@/core/interfaces/role';
import { Phone } from '@/core/interfaces/phone';

let syncIntervalId = 0;
let syncRunsIntervalId = 0;
let syncAlarmerStatusIntervalId = 0;

const syncIntervalMS = 1000 * 60 * 10; // every 10 minutes
const syncRunsIntervalMS = 1000 * 60; // every minute
const syncAlarmerStatusIntervalMS = 1000 * 10; // every 10 seconds

export const syncMasterData = async(context: ActionContext<RootState, RootState>) => {
    return Promise.all([
        context.dispatch('syncFarms'),
        context.dispatch('syncNotificationTypes'),
        context.dispatch('syncFarmVehicleTypes'),
        context.dispatch('syncVehiclePhases'),
        context.dispatch('syncUsers'),
        context.dispatch('syncVehicles'),
        context.dispatch('syncVehicleTypes'),
        context.dispatch('syncRoles'),
        context.dispatch('syncUserRoles'),
        context.dispatch('syncPhones'),
        context.dispatch('syncAlarmerStatus'),
    ]);
};

export const initSyncInterval = (context: ActionContext<RootState, RootState>) => {
    context.dispatch('stopSyncInterval');

    syncIntervalId = window.setInterval(() => context.dispatch('syncMasterData'), syncIntervalMS);
    syncRunsIntervalId = window.setInterval(() =>
        context.dispatch('runs/syncRuns'), syncRunsIntervalMS);
    syncAlarmerStatusIntervalId = window.setInterval(() => context.dispatch('syncAlarmerStatus'), syncAlarmerStatusIntervalMS);
};

export const stopSyncInterval = () => {
    if (syncIntervalId) {
        window.clearInterval(syncIntervalId);
    }

    if (syncRunsIntervalId) {
        window.clearInterval(syncRunsIntervalId);
    }

    if (syncAlarmerStatusIntervalId) {
        window.clearInterval(syncAlarmerStatusIntervalId);
    }
};

export const syncAlarmerStatus = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/alarmer-client/status');
    context.commit('setAlarmerStatus', res.data);
};

export const syncFarms = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/farms');
    context.commit('setFarms', res.data);
};

export const syncFarmVehicleTypes = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/farm-vehicle-types');
    context.commit('setFarmVehicleTypes', res.data);
};

export const syncNotificationTypes = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/notification-types');
    context.commit('setNotificationTypes', res.data);
};

export const syncVehiclePhases = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/vehicle-phases');
    context.commit('setVehiclePhases', res.data);
};

export const syncUsers = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/users');
    context.commit('setUsers', res.data);
};

export const syncUserRoles = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/userroles');
    context.commit('setUserRoles', res.data);
};

export const syncVehicles = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/vehicles');
    context.commit('setVehicles', res.data);
};

export const syncRoles = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/roles');
    context.commit('setRoles', res.data);
};

export const syncVehicleTypes = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/vehicle-types');
    context.commit('setVehicleTypes', res.data);
};

export const saveRoleUsers = async(context: ActionContext<RootState, RootState>, payload: { role: Role; users: number[] }) => {
    const currentUserRoles = context.state.userRoles.filter(userRole => userRole.role_id === payload.role.id);
    const currentUserIds = currentUserRoles.map(userRole => userRole.user_id);

    const postData = payload.users
        .filter(user => !currentUserIds.includes(user))
        .map(user => ({
            user_id: user,
            role_id: payload.role.id,
        }));

    const post: Promise<void> = postData.length ? axios.post('/userroles', postData) : Promise.resolve();

    const deletes = currentUserRoles
        .filter(userRole => !payload.users.includes(userRole.user_id))
        .map(userRole => axios.delete(`/userroles/${userRole.id}`));

    await Promise.all([post, ...deletes]);
    await context.dispatch('syncUserRoles');
};

export const syncPhones = async(context: ActionContext<RootState, RootState>) => {
    const res = await axios.get('/phones');
    context.commit('setPhones', res.data);
};

export const deletePhone = async(context: ActionContext<RootState, RootState>, payload: number) => {
    await axios.delete(`/phones/${payload}`);
    context.commit('deletePhone', payload);
};

export const savePhone = async(context: ActionContext<RootState, RootState>, payload: Partial<Phone>) => {
    const phone = (await axios.put(`/phones/${payload.id}`, payload)).data;
    context.commit('updatePhone', phone);
};
