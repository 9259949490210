


































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import Heatmap from '@/components/heatmap/Heatmap.vue';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { VehicleType } from '@/core/interfaces/vehicleType';
import vSelect from 'vue-select';
import { format, subDays, subMonths } from 'date-fns';
import { NotificationType } from '@/core/interfaces/notificationType';
import axios from 'axios';
import NotificationLevel, { AllNotificationLevels } from '@/core/enums/notificationLevel';
import TranslatesNotificationLevel from '@/components/mixins/TranslatesNotificationLevel';
import filtersToUrlParams from '@/core/FiltersToUrlParams';
import { MapData } from '@/core/interfaces/mapData';
import { MapOffset } from '@/core/interfaces/mapOffset';
import { HeatmapNotification } from '@/core/interfaces/heatmapNotification';

@Component({
    components: { vSelect, Heatmap, TopnavLayout },
})
export default class HeatmapView extends Mixins(TranslatesNotificationLevel) {
    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];

    @Getter('vehicleTypesByFarm')
    vehicleTypesByFarm!: (id: number) => VehicleType[];

    @Getter('notificationTypesSet')
    notificationTypes!: NotificationType[];

    loading = false;
    farm: Farm | null = null;
    vehicleType: VehicleType | null = null;
    dateFrom: string | null = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
    dateUntil: string | null = format(new Date(), 'yyyy-MM-dd');
    todayDate = format(new Date(), 'yyyy-MM-dd');
    notificationTypeIds: number[] = [];
    notificationLevels: NotificationLevel[] = [NotificationLevel.ERROR];
    notifications: HeatmapNotification[] = [];
    mapBackground: Blob | null = null;
    mapData: MapData | null = null;
    mapOffset: MapOffset | null = null;
    type: 'heatmap' | 'clusters' = 'heatmap';

    public getNotificationTypeLabel(notificationType: NotificationType): string {
        const t = `${this.$store.getters.vehicleTypeById(notificationType.vehicle_type_id).name}.notification-types.${notificationType.code}`;

        return `${notificationType.code} - ${this.$t(t)}`;
    }

    get notificationTypesFiltered(): NotificationType[] {
        if (!this.vehicleType) {
            return [];
        }

        const notificationTypes = [...this.notificationTypes]
            .filter(notificationType => this.vehicleType?.id === notificationType.vehicle_type_id);

        return notificationTypes.sort((typeA, typeB) => {
            const vehicleTypeA = this.$store.getters.vehicleTypeById(typeA.vehicle_type_id) as VehicleType;
            const vehicleTypeB = this.$store.getters.vehicleTypeById(typeB.vehicle_type_id) as VehicleType;

            return vehicleTypeA?.name.localeCompare(vehicleTypeB.name);
        });
    }

    get vehicleTypesFiltered(): VehicleType[] {
        if (!this.farm) {
            return [];
        }

        return this.vehicleTypesByFarm(this.farm.id);
    }

    async fetchNotifications(): Promise<void> {
        if (!this.farm || !this.vehicleType) {
            return;
        }

        this.loading = true;

        const filters = {
            farmId: this.farm?.id,
            vehicleTypeId: this.vehicleType?.id,
            dateFrom: this.dateFrom,
            dateUntil: this.dateUntil,
            notificationLevels: this.notificationLevels,
            notificationTypeIds: this.notificationTypeIds,
        };

        this.notifications = (await axios.get<HeatmapNotification[]>('/notifications/heatmap', {
            params: filtersToUrlParams(filters),
        })).data;

        this.loading = false;
    }

    @Watch('farm')
    @Watch('vehicleType')
    async fetchMapData(): Promise<void> {
        this.mapBackground = null;
        this.mapData = null;
        this.mapOffset = null;

        if (!this.farm || !this.vehicleType) {
            return;
        }

        const responses = await Promise.all([
            axios.get<Blob>(`/farms/${this.farm.id}/map/${this.vehicleType.id}/background`, {
                responseType: 'blob',
            }),
            axios.get<MapData>(`/farms/${this.farm.id}/map/${this.vehicleType.id}/json`),
            axios.get<MapOffset>(`/farms/${this.farm.id}/vehicle-types/${this.vehicleType.id}/map-offset`),
        ]);

        this.mapBackground = responses[0].data;
        this.mapData = responses[1].data;
        this.mapOffset = responses[2].data;
    }

    get notificationLevelOptions(): { text: string; value: NotificationLevel }[] {
        return AllNotificationLevels.map(level => ({
            text: this.translateNotificationLevel(level),
            value: level,
        }));
    }

    @Watch('farm')
    onFarmUpdate(): void {
        this.vehicleType = null;
        this.notifications = [];
    }

    @Watch('vehicleType')
    onVehicleTypeUpdate(): void {
        this.notificationTypeIds = [];
        this.notifications = [];
    }
}

