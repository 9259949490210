








import RunsTableFilters from '@/components/run/runs-table/RunsTableFilters.vue';
import RunsTableViewMode from '@/core/enums/runsTableViewMode';
import { Notification } from '@/core/interfaces/notification';
import axios from 'axios';
import filtersToUrlParams from '@/core/FiltersToUrlParams';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import writeXlsxFile, { Schema } from 'write-excel-file';
import FormatsDate from '@/components/mixins/FormatsDate';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';

@Component({})
export default class ExportNotificationsButton extends Mixins(UsesNotificationMessage, TranslatesVehiclePhase, FormatsDate) {
    @Prop()
    filters!: RunsTableFilters | null;

    @Prop({ default: RunsTableViewMode.OPEN })
    view!: RunsTableViewMode;

    loading = false;

    async exportNotifications(): Promise<void> {
        this.loading = true;
        const notifications = await this.loadNotifications();
        const datetime = this.formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
        const fileName = `notifications-${datetime}.xlsx`;

        const schema = [
            {
                column: 'NotificationId',
                type: Number,
                value: notification => notification.id,
            },
            {
                column: 'Code',
                type: Number,
                value: notification => this.$store.getters.notificationTypeById(notification.notification_type_id).code,
            },
            {
                column: 'Message',
                type: String,
                value: notification => this.translateNotificationMessage(
                    this.$store.getters.notificationTypeById(notification.notification_type_id),
                    this.$store.getters.vehicleTypeById(notification.vehicle_type_id),
                ),
                width: 40,
            },
            {
                column: 'Phase',
                type: String,
                value: notification =>
                    this.translateVehiclePhase(this.$store.getters.vehiclePhaseById(notification.vehicle_phase_id)),
                width: 20,
            },
            {
                column: 'CreatedAt',
                type: Date,
                value: notification => {
                    const date = new Date(notification.created_at);

                    // date is in UTC, convert to local time
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

                    return date;
                },
                format: 'yyyy-mm-dd hh:mm:ss',
                width: 20,
            },
            {
                column: 'X',
                type: Number,
                value: notification => notification.x,
            },
            {
                column: 'Y',
                type: Number,
                value: notification => notification.y,
            },
            {
                column: 'FarmName',
                type: String,
                value: notification => notification.farm_name,
            },
            {
                column: 'VehicleName',
                type: String,
                value: notification => notification.vehicle_name,
            },
            {
                column: 'Level',
                type: String,
                value: notification => notification.notification_level,
            },
        ] as Schema<Notification>;

        try {
            return writeXlsxFile(notifications, {
                schema,
                fileName,
                stickyRowsCount: 1,
            });
        } finally {
            this.loading = false;
        }
    }

    async loadNotifications(): Promise<Notification[]> {
        const query: Record<string, any> = { ...(this.filters || {}) };

        query.onlyOpen = this.view === RunsTableViewMode.OPEN;
        query.archived = this.view === RunsTableViewMode.ARCHIVED;
        query.limit = 2000;

        if (this.$store.state.visibleNotificationLevels.length) {
            query.notificationLevels = this.$store.state.visibleNotificationLevels;
        }

        if (this.$store.state.visibleVehicleTypeIds.length) {
            query.vehicleTypeIds = this.$store.state.visibleVehicleTypeIds;
        }

        const res = await axios.get('/notifications', {
            params: filtersToUrlParams(query),
        });

        return res.data.notifications;
    }
}
