




















import { Component, Prop, Vue } from 'vue-property-decorator';
import NavigationTop from '@/components/navigation/NavigationTop.vue';

@Component({
    components: {
        NavigationTop,
    },
})
export default class TopnavLayout extends Vue {
    @Prop({ type: Boolean })
    noPadding!: boolean;
}

