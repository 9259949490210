import { render, staticRenderFns } from "./TopnavLayout.vue?vue&type=template&id=4318642e&scoped=true&"
import script from "./TopnavLayout.vue?vue&type=script&lang=ts&"
export * from "./TopnavLayout.vue?vue&type=script&lang=ts&"
import style0 from "./TopnavLayout.vue?vue&type=style&index=0&id=4318642e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4318642e",
  null
  
)

export default component.exports