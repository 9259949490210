




import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import L from 'leaflet';
import MapMixin from '@/components/mixins/MapMixin';
import { MapOffset } from '@/core/interfaces/mapOffset';
import { HeatmapNotification } from '@/core/interfaces/heatmapNotification';
import 'leaflet.heat';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { MapLayer } from '@/core/interfaces/mapLayer';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({
    components: {},
})
export default class Heatmap extends Mixins(MapMixin, UsesNotificationMessage) {
    @Prop({ required: true })
    notifications!: HeatmapNotification[];

    @Prop({ required: true })
    mapOffset!: MapOffset;

    @Prop({ required: true })
    mapLayers!: MapLayer[];

    @Prop({ required: true })
    mapBackground!: Blob;

    @Prop({ default: null })
    mapBackgroundOffset!: { x: number; y: number } | null;

    @Prop({ required: true })
    mapBackgroundResolution!: number;

    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Prop({ default: 'heatmap' })
    type!: 'heatmap' | 'clusters';

    layer: L.Layer | null = null;

    notificationTypeCache: { [key: string]: string } = {};

    async mounted(): Promise<void> {
        const offset = this.mapBackgroundOffset ? this.getLatLngTupleFromOffset(this.mapBackgroundOffset) : undefined;

        await this.initMap({
            elem: this.$refs.map as HTMLElement,
            offset,
            mapOffset: [this.mapOffset.offset_lat, this.mapOffset.offset_lng],
            layers: this.mapLayers,
            background: {
                image: this.mapBackground,
                resolution: this.mapBackgroundResolution,
                offset,
            },
            defaultLayer: 'farm',
        });
    }

    @Watch('notifications')
    @Watch('type')
    drawNotifications(): void {
        if (this.layer) {
            this.map.removeLayer(this.layer);
        }

        if (this.type === 'heatmap') {
            // @ts-ignore
            this.layer = new L.HeatLayer(this.notifications.filter(notification => notification.x && notification.y).map(notification => [notification.y, notification.x]), { radius: 25 }).addTo(this.map);
        } else {
            this.layer = this.createMarkerClusterGroup().addTo(this.map);
        }
    }

    createMarkerClusterGroup(): L.Layer {
        // @ts-ignore
        const markers = L.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: false,
        });

        this.notifications.forEach(notification => {
            const title = this.createNotificationTypeTitle(notification.notification_type_id);

            const marker = L.marker(new L.LatLng(notification.y, notification.x), {
                // @ts-ignore
                notificationTypeId: notification.notification_type_id,
                icon: L.icon({
                    className: 'marker',
                    tooltipAnchor: [20, 35],
                    iconUrl: '/img/leaflet/marker-icon.png',
                    shadowUrl: '/img/leaflet/marker-shadow.png',
                }),
            });

            marker.bindTooltip(title);
            markers.addLayer(marker);
        });

        markers.on('clusterclick', (event: any) => {
            const notificationTypeIds: number[] = event.layer.getAllChildMarkers().map((marker: any) => marker.options.notificationTypeId);
            const uniqueNotificationTypeIds = [...new Set(notificationTypeIds)];

            const tooltip = uniqueNotificationTypeIds.map(notificationTypeId => {
                const title = this.createNotificationTypeTitle(notificationTypeId);
                const numberOfNotifications = notificationTypeIds.filter(id => id === notificationTypeId).length;

                return `<li>${title}: ${numberOfNotifications}x</li>`;
            });

            const tooltipContent = `<ul>${tooltip.join('')}</ul>`;
            const marker = markers.getVisibleParent(event.layer);

            L.popup()
                .setLatLng(marker.getLatLng())
                .setContent(tooltipContent)
                .openOn(this.map);
        });

        return markers;
    }

    createNotificationTypeTitle(notificationTypeId: number): string {
        if (this.notificationTypeCache[notificationTypeId]) {
            return this.notificationTypeCache[notificationTypeId];
        }

        const notificationType = this.$store.getters.notificationTypeById(notificationTypeId);
        const title = this.translateNotificationMessage(notificationType, this.vehicleType);

        this.notificationTypeCache[notificationTypeId] = title;

        return title;
    }
}
