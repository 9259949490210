import { Component, Vue } from 'vue-property-decorator';
import L, {
    Circle,
    CRS,
    GeoJSONOptions,
    ImageOverlay,
    LatLng,
    LatLngBounds,
    LatLngTuple,
    LeafletMouseEvent,
} from 'leaflet';
import { GeoJSON } from 'geojson';
import { MapLayer } from '@/core/interfaces/mapLayer';
import SimpleCRSWMS from '@/core/leaflet/SimpleCRSWMS'

const colors = [
    '#850C18',
    '#4D33DC',
    '#73BD07',
    '#FFC700',
    '#0099BB',
    '#FF7921',
];

interface MapOptions {
    elem: HTMLElement;
    center?: LatLngTuple;
    background?: { image: Blob; resolution: number; offset?: LatLngTuple };
    offset?: LatLngTuple;
    layers?: MapLayer[];
    mapOffset?: LatLngTuple;
    defaultLayer?: 'farm' | 'mixed' | 'aerial';
}

@Component
export default class MapMixin extends Vue {
    map!: L.Map;
    controlLayers!: L.Control.Layers;

    colorIndex = 0;

    protected async initMap(options: MapOptions, leafletOptions: L.MapOptions = {}) {
        this.map = new L.Map(options.elem, {
            crs: L.CRS.Simple,
            attributionControl: false,
            zoomControl: true,
            zoomSnap: 0.5,
            zoomDelta: 0.5,
            minZoom: -5,
            maxZoom: 7,
            ...leafletOptions,
        });

        if (options.center) {
            this.map.setView(MapMixin.removeOffset(options.center, options.offset), 3);
        }

        this.controlLayers = L.control.layers({}, {}, {
            position: 'topleft',
            collapsed: false,
        });

        await this.createBaseLayers(options);

        if (options.layers) {
            this.drawLayers(options.layers);
        }

        this.initMousePosition(options.offset);
    }

    protected async createBaseLayers(options: MapOptions): Promise<void> {
        this.map.createPane('background');
        // @ts-ignore
        this.map.getPane('background').style.zIndex = 1;

        let aerial: L.TileLayer.WMS | undefined;
        let background: L.ImageOverlay | undefined;
        let farm: L.LayerGroup | undefined;

        if (options.background) {
            background = await this.createBackground(options.background.image, options.background.resolution, options.background.offset);
            if (!options.center) {
                this.map.fitBounds(background.getBounds());
            }

            const rectangle = new L.Rectangle(background.getBounds(), {
                fillColor: 'white',
                color: 'black',
                fillOpacity: 1,
                weight: 1,
                interactive: false,
                pane: 'background',
            });

            farm = new L.LayerGroup([
                rectangle,
                background,
            ]);

            rectangle.bringToBack();
            background.bringToFront();

            this.controlLayers.addBaseLayer(farm, 'Farm');
        }

        if (options.mapOffset && options.mapOffset[0] && options.mapOffset[1]) {
            aerial = this.createAerial(options.mapOffset, options.offset);

            this.controlLayers.addBaseLayer(aerial, 'Aerial');
        }

        let mixed: L.LayerGroup | undefined;

        if (background && options.mapOffset && options.mapOffset[0] && options.mapOffset[1]) {
            mixed = new L.LayerGroup([
                this.createAerial(options.mapOffset, options.offset),
                background,
            ]);
            this.controlLayers.addBaseLayer(mixed, 'Mixed');
        }

        if (mixed && options.defaultLayer === 'mixed') {
            this.map.addLayer(mixed);
        } else if (farm && options.defaultLayer === 'farm') {
            this.map.addLayer(farm);
        } else if (aerial && options.defaultLayer === 'aerial') {
            this.map.addLayer(aerial);
        } else if (mixed) {
            this.map.addLayer(mixed);
        } else if (farm) {
            this.map.addLayer(farm);
        } else if (aerial) {
            this.map.addLayer(aerial);
        }
    }

    protected drawLayers(layers: MapLayer[], subtractOffset?: [number, number]): void {
        layers.forEach(layer => {
            const color = layer.color ? layer.color : this.getColor();
            const geojsonLayer = this.createGeoJSONLayer(layer.geojson, color, subtractOffset).addTo(this.map);
            this.addLayerToControl(layer.title, color, geojsonLayer);
        });
    }

    protected getColor(): string {
        return colors[this.colorIndex++ % colors.length];
    }

    protected createGeoJSONLayer(geojsonData: GeoJSON, color?: string, subtractOffset?: [number, number]): L.GeoJSON {
        const opts: GeoJSONOptions = {
            coordsToLatLng(coords: [number, number]): L.LatLng {
                return L.GeoJSON.coordsToLatLng(MapMixin.removeOffset(coords, subtractOffset ? [subtractOffset[1], subtractOffset[0]] : undefined));
            },
            pointToLayer: (feature, latlng) => {
                if (feature.properties?.interactive) {
                    return L.circleMarker(latlng, {
                        interactive: true,
                        radius: 5,
                        weight: 2,
                        fillColor: color,
                        opacity: 1,
                        fillOpacity: 0.4,
                    });
                } else {
                    return L.circleMarker(latlng, {
                        interactive: false,
                        radius: 2,
                        weight: 0,
                        fillColor: color,
                        opacity: 1,
                        fillOpacity: 0.8,
                    });
                }
            },
        };

        if (color) {
            opts.style = {
                color: color,
                fillColor: color,
            };
        }

        const geojson = L.geoJSON(
            geojsonData,
            opts,
        );

        geojson.bindTooltip(featureLayer => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const name = (featureLayer as any).feature.properties?.name;

            if (name) {
                return name;
            }

            return null;
        }, {
            sticky: true,
        });

        return geojson;
    }

    protected addLayerToControl(title: string, color: string, layer: L.Layer) {
        const name = `${title} <span class="color-indicator" style="background-color: ${color}"></span>`;

        this.controlLayers.addOverlay(layer, name);

        this.map.addControl(this.controlLayers);
    }

    protected async createBackground(image: Blob, resolution: number, offset?: LatLngTuple): Promise<ImageOverlay> {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(image);

        return new Promise(resolve => {
            img.onload = () => {
                const bounds = new LatLngBounds([
                    MapMixin.addOffset([0, 0], offset),
                    MapMixin.addOffset([img.height * resolution, img.width * resolution], offset),
                ]);

                resolve(L.imageOverlay(
                    img.src,
                    bounds,
                ));
            };
        });
    }

    protected createAerial(mapOffset: LatLngTuple, backgroundOffset?: LatLngTuple): L.TileLayer.WMS {
        // @ts-ignore
        return new SimpleCRSWMS('https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0', {
            layers: 'Actueel_orthoHR',
            version: '1.3.0',
            crs: L.CRS.EPSG4326,
            transformBounds(bounds: L.LatLngBounds) {
                return new L.LatLngBounds(
                    MapMixin.fromYXToLatLng(
                        MapMixin.addOffset([bounds.getSouthWest().lat, bounds.getSouthWest().lng], backgroundOffset),
                        mapOffset,
                    ),
                    MapMixin.fromYXToLatLng(
                        MapMixin.addOffset([bounds.getNorthEast().lat, bounds.getNorthEast().lng], backgroundOffset),
                        mapOffset,
                    ),
                );
            },
        })
    }

    private initMousePosition(offset?: LatLngTuple): void {
        const MousePositionControl = L.Control.extend({
            onAdd: (map: L.Map) => {
                const div = L.DomUtil.create('div') as HTMLImageElement;
                div.className = 'mouse-position-control';

                map.on('mousemove', (event: LeafletMouseEvent) => {
                    const coordinatesWithOffset = MapMixin.addOffset([event.latlng.lat, event.latlng.lng], offset);
                    div.innerHTML = `${coordinatesWithOffset[1].toFixed(2)} ${coordinatesWithOffset[0].toFixed(2)}`;
                    div.style.display = 'block';
                });

                map.on('mouseout', () => {
                    div.innerHTML = '';
                    div.style.display = 'none';
                });

                return div;
            },
        });

        (new MousePositionControl({position: 'bottomleft'})).addTo(this.map);
    }

    private static removeOffset(coordinate: LatLngTuple, offset?: LatLngTuple): LatLngTuple {
        if (!offset) {
            return coordinate;
        }

        return [
            coordinate[0] - offset[0],
            coordinate[1] - offset[1],
        ];
    }

    public static addOffset(coordinate: LatLngTuple, offset?: LatLngTuple): LatLngTuple {
        if (!offset) {
            return coordinate;
        }

        return [
            coordinate[0] + offset[0],
            coordinate[1] + offset[1],
        ];
    }

    public static fromYXToLatLng(point: LatLngTuple, mapOffset: LatLngTuple): LatLngTuple {
        // Earth’s radius, sphere
        const R = 6378137;
        const pi = Math.PI;

        // Coordinate offsets in radians
        const dLat = point[0] / R;
        const dLon = point[1] / (R * Math.cos(pi * mapOffset[0] / 180));

        // OffsetPosition, decimal degrees
        const lat0 = mapOffset[0] + dLat * 180 / pi;
        const lon0 = mapOffset[1] + dLon * 180 / pi;

        return [lat0, lon0];
    }

    getLatLngTupleFromOffset(offset: { x: number; y: number }): LatLngTuple {
        return [offset.y, offset.x] as LatLngTuple;
    }

    circleToLatLngs(circle: Circle, vertices = 60): LatLng[] {
        const latLngs: L.LatLng[] = [];
        const crs = this.map.options.crs;
        const DOUBLE_PI = Math.PI * 2;
        let angle = 0.0;
        let radius;
        let point;
        let project;
        let unproject;

        if (crs === L.CRS.EPSG3857) {
            project = this.map.latLngToLayerPoint.bind(this.map);
            unproject = this.map.layerPointToLatLng.bind(this.map);
            radius = circle.getRadius();
        } else { // especially if we are using Proj4Leaflet
            // @ts-ignore
            project = crs.projection.project.bind(crs.projection);
            // @ts-ignore
            unproject = crs.projection.unproject.bind(crs.projection);
            // @ts-ignore
            radius = circle._mRadius;
        }

        const projectedCentroid = project(circle.getLatLng());

        for (let i = 0; i < vertices - 1; i++) {
            angle -= (DOUBLE_PI / vertices); // clockwise
            point = new L.Point(
                projectedCentroid.x + (radius * Math.cos(angle)),
                projectedCentroid.y + (radius * Math.sin(angle)),
            );
            latLngs.push(unproject(point));
        }

        return latLngs;
    }
}
