


































import { Component, Vue } from 'vue-property-decorator';
import SettingsRules from '@/components/settings/rule/SettingsRules.vue';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import { Getter } from 'vuex-class';

@Component({
    components: { TopnavLayout, SettingsRules },
})
export default class SettingsView extends Vue {
    @Getter('isAdmin')
    isAdmin!: boolean;

    mounted() {
        this.$store.dispatch('settings/syncSettings');
    }
}

